import styles from "../styles/components/Info.module.css";

export function Info() {
  return (
    <div className={styles.section}>
      <div className={styles.title}>
        <img src="./icons/logo.svg" alt="Logo Condomínio" />
      </div>

      <div className={styles.container}>
        <div className={styles.mapCard}>
          <a
            href="https://goo.gl/maps/zv8cy6TJtZw35Udr7"
            target="_blank"
            rel="noreferrer"
            style={{ display: `flex` }}
          >
            <div className={styles.icon}>
              <img src="./icons/map.png" alt="" />
            </div>
            <div className={styles.text}>
              <p>
                R. Padre Anchieta, 4715 - 96015-420 <br />
                Centro, Pelotas - RS
              </p>
            </div>
          </a>
        </div>

        <div className={styles.card}>
          <div className={styles.icon}>
            <img src="./icons/phone.png" alt="" />
          </div>
          <div className={styles.text}>
            <p>+55 53 30256797</p>
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.icon}>
            <img src="./icons/email.png" alt="" />
          </div>
          <div className={styles.mailText}>
            <p>faleconosco@condominioruabrasil.com.br</p>
          </div>
        </div>
      </div>
    </div>
  );
}
