import React from "react";

import styles from "../styles/components/Modal.module.css";

export const Modal = (props) => {
  if (!props.show) {
    return null;
  }

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        {/* <div className={styles.modalHeader}>
          <button onClick={props.onClose}>
            <img src="./icons/close.png" alt="Fechar" />
          </button>
        </div> */}
        <div className={styles.modalHeader}></div>

        <div className={styles.modalBody}>
          <h1>Mensagem enviada!</h1>
          <h2>Entraremos em contato em breve</h2>
        </div>

        <div className={styles.modalFooter}>
          <button onClick={() => window.location.reload()}>OK</button>
        </div>
      </div>
    </div>
  );
};
