import React, { useState, useRef } from "react";
import axios from "axios";
import Recaptcha from "react-recaptcha";

import { Modal } from "../components/Modal";

import styles from "../styles/components/Form.module.css";

export function Form() {
  const [isVerified, setIsVerified] = useState(false);

  const [show, setShow] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const button = useRef(null);

  function recaptchaLoaded() {
    console.log("recaptcha successfully loaded");
  }

  function verifyCallback(response) {
    if (response) {
      setIsVerified(true);
    }
  }

  function handleSubmit(event) {
    console.log("isVerified");
    event.preventDefault();
    axios.post(
      "https://api-crbr.grupomeza.com.br/?to=faleconosco@condominioruabrasil.com.br",
      { name, email, message }
    );
    setName("");
    setEmail("");
    setMessage("");

    setShow(true);
  }

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <div className={styles.title}>
          <h1>Entre em contato conosco</h1>
        </div>

        <form id="myForm">
          <fieldset className="fieldset">
            <div className={styles.formGroup}>
              <label htmlFor="name">Nome</label>
              <input
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Seu nome completo"
                required
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="email" email="true">
                E-mail
              </label>
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Seu e-mail"
                required
              />
            </div>

            <div className={styles.formGroupMessage}>
              <label htmlFor="message">Mensagem</label>
              <textarea
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Sua mensagem"
                rows="5"
                required
              ></textarea>
            </div>

            <Recaptcha
              style={{ display: "inline-block" }}
              sitekey="6LftmpUaAAAAAIRXvadSL_kdntrN6traiy9Nho3N"
              // ↑ chave oficial
              // sitekey="6Le_500bAAAAADPCGKZk7zi0zjbdzpEnrt0PzATK"
              // ↑ chave de teste (captcha funcional em localhost)
              render="explicit"
              onloadCallback={recaptchaLoaded}
              verifyCallback={verifyCallback}
            />

            <button
              ref={button}
              type="submit"
              value="submit"
              disabled={
                name === "" ||
                email === "" ||
                message === "" ||
                isVerified === false
                  ? true
                  : false
              }
              onClick={handleSubmit}
            >
              ENVIAR
            </button>
          </fieldset>
        </form>
      </div>
      <Modal onClose={() => setShow(false)} show={show} />
    </div>
  );
}
