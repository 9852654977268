import React from "react";

import { Form } from "./components/Form ";
import { Info } from "./components/Info";

import "./styles/global.css";

function App() {
  return (
    <div className="page">
      <div className="container">
        <div className="containerInfo">
          <Info />
        </div>

        <div className="containerForm">
          <Form />
        </div>
      </div>
    </div>
  );
}

export default App;
